//----------------------------------*\
// Fonts
//----------------------------------*/
@import 'assets/styles/_mixins.scss';
@import 'assets/styles/fonts/rubik';
@import 'assets/styles/fonts/icons';
// primary font
$primary_font: $Rubik; // font-weight
$thin: 100;
$light: 300;
$regular: 400;
$bold: 700;
$black: 900; // Font size
$font-xs: 12px;
$font-sm: 14px;
$primary-fs: 16px;
$font-md: 18px;
$font-lg: 20px;
body {
    font-family: $primary_font;
    font-size: $primary-fs;
    font-weight: $regular;
}

@import '~@angular/material/theming';
@include mat-core();
$new-portal-primary: mat-palette($mat-indigo);
$new-portal-accent: mat-palette($mat-pink, A200, A100, A400);
// The warn palette is optional (defaults to red).
$new-portal-warn: mat-palette($mat-red);
$new-portal-white: mat-palette($mat-indigo, 50);
// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$new-portal-theme: mat-light-theme((
    color: (
        primary: $new-portal-primary,
        accent: $new-portal-accent,
        warn: $new-portal-warn,
        white: $new-portal-white
    )
));

$custom-typography: mat-typography-config(
    $font-family: $Rubik,
    $headline: mat-typography-level(32px, 48px, 700),
    $body-1: mat-typography-level(16px, 24px, 400)
);

// Override typography CSS classes (e.g., mat-h1, mat-display-1, mat-typography, etc.).
@include mat-base-typography($custom-typography);

// Override typography for a specific Angular Material components.
@include mat-checkbox-typography($custom-typography);

// Override typography for all Angular Material, including mat-base-typography and all components.
@include angular-material-typography($custom-typography);

@include angular-material-theme($new-portal-theme);

:root {
    --theme-client-color: white;
    --theme-client-background: white;
    --theme-client-red: #f44336;
    --theme-client-green: #4caf50;
    --primary-color: #285992;
}

.mat-drawer-content {
    background: #fff;
}

section {
    position: relative;
}

html, body {
    height: 100%;
    scroll-behavior: smooth;
}

body {
    margin: 0;
    font-family: $Rubik, "Helvetica Neue", sans-serif;
}

*:focus {
    outline: none !important;
}

img {
    aspect-ratio: attr(width) / attr(height);
}

@media screen and (prefers-reduced-motion: reduce) {
    html {
        scroll-behavior: auto;
    }
}

.img-full {
    width: 100%;
    height: auto;
}

p {
    font-weight: 300;
    color: #484848;

    &.loading {
        @include loading($flag: false);
    }

    img {
        max-width: 100%;
    }
}

h1, h2, h3, h4, h5, h6 {
    color: #484848;

    &.loading {
        @include loading($flag: false);
    }
}

.infochat_custommain,
.infochat_floatmain,
.infochat_theme1,
.infochat_cnt,
.infochat_theme2,
.infochat_flt_rel {
    @media screen and(max-width: 576px){
        margin-bottom: 90px;
        z-index: 999 !important;
    }
    &.zIndex {
        z-index: 999 !important;
    }
    &.zIndexZero {
        z-index: 0 !important;
    }
}

figure {
    margin: 0;

    &.accomodation {
        &.loading {
            @include loading($flag: true, $custom: true);
        }
    }

    &.package {
        position: relative;

        &.loading {
            @include loading($flag: true, $custom: true);
        }

        .discount {
            position: absolute;
            right: 0;
            top: 20px;
            padding: .25rem .5rem;
            color: #fff;
            background: var(--theme-client-color);

            @media screen and(max-width: 576px){
                font-size: 1.25rem;
            }
        }
    }

    &.loading {
        @include loading($flag: true, $custom: false);
    }
}

button {
    &.loading {
        @include loading($flag: true);
    }

    &:disabled {
        background: #0000001f !important;
        color: #00000042 !important;;
    }
}

.mat-button.mat-primary,
.mat-icon-button.mat-primary,
.mat-stroked-button.mat-primary {
    color: var(--theme-client-color);
}

.mat-drawer-inner-container {
    min-width: 250px;
}

.swiper-container {
    z-index: 0;
}

.pagination-accommodation {
    text-align: center;

    .swiper-pagination-bullet {
        width: 10px;
        height: 10px;
        border-radius: 0;
    }

    .swiper-pagination-bullet-active {
        background: var(--theme-client-color) !important;
    }

    &.pagination-video {
        bottom: -5px !important;
    }

    &.pagination-images {
        .swiper-pagination-bullet-active {
            background: rgba(255, 255, 255, 0.75) !important;
        }
    }
}

.mat-checkbox-indeterminate.mat-primary .mat-checkbox-background,
.mat-checkbox-checked.mat-primary .mat-checkbox-background {
    background-color: var(--theme-client-color);
}

.mat-checkbox-layout {
    color: #484848;
    font-size: .95rem;
}

.swiper-button-next-slide,
.swiper-button-prev-slide {
    background: var(--theme-client-color);
    opacity: .85;
    color: white;
    padding: 1.25rem;
    display: flex;
    align-items: center;
    justify-content: center;

    position: absolute;
    top: calc(50% - 40px);
    width: calc(var(--swiper-navigation-size) / 44 * 27);
    height: var(--swiper-navigation-size);
    margin-top: calc(-1 * var(--swiper-navigation-size) / 2);
    z-index: 10;
    cursor: pointer;
    min-height: 90px;

    &.swiper-button-disabled {
        opacity: 1;
        cursor: auto;
        pointer-events: none;
        border: solid 1px white;
        background: transparent;
    }
}

.swiper-button-next-cart,
.swiper-button-prev-cart {
    position: absolute;
    top: calc(50% - 20px);
    width: calc(var(--swiper-navigation-size) / 44 * 27);
    height: var(--swiper-navigation-size);
    z-index: 10;
    cursor: pointer;
    background: transparent;
    opacity: 1;
    color: #484848;
    padding: .25rem;
    display: flex;
    align-items: center;
    justify-content: center;

    &.slideshow {
        color: white;
        background: var(--theme-client-color);
        opacity: .75;
        mat-icon {
            width: 2rem;
            height: 3rem;
            display: flex;
            align-items: center;
            justify-content: center;
        }
        .material-icons {
            font-size: 1.5rem;
        }

        &.swiper-button-disabled {
            opacity: .25;
            cursor: auto;
            pointer-events: none;
            background: var(--theme-client-color) !important;
        }
    }

    &.swiper-button-disabled {
        opacity: .5;
        cursor: auto;
        pointer-events: none;
        background: transparent;
    }
}

.swiper-button-next-cart {
    right: 0;
    left: auto;
}
.swiper-button-prev-cart {
    left: 0;
    right: auto;
}

.swiper-button-next-slide {
    right: 30px;
    left: auto;
}

.swiper-button-prev-slide {
    left: 30px;
    right: auto;
}

.amenities-button-next,
.amenities-button-prev {
    position: absolute;
    top: 50%;
    width: calc(var(--swiper-navigation-size) / 44 * 27);
    height: var(--swiper-navigation-size);
    margin-top: calc(-1 * var(--swiper-navigation-size) / 2);
    z-index: 10;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #484848;

    &.swiper-button-disabled {
        opacity: 0.35;
        cursor: auto;
        pointer-events: none;
    }
}

.amenities-button-next {
    right: 0;
    left: auto;
}

.amenities-button-prev {
    right: auto;
    left: 0;
}

.images-pagination {
    .swiper-pagination-bullet {
        width: 10px;
        height: 10px;
        border-radius: 0;
    }

    .swiper-pagination-bullet-active {
        background: rgba(255, 255, 255, .75) !important;
    }
}

mat-form-field {
    width: 100%;
    font-size: 0.875rem;
}

.mat-form-field-appearance-outline .mat-form-field-wrapper {
    margin: 0 !important;
}

.mat-radio-label-content {
    @media (max-width: 768px) {
        white-space: break-spaces;
    }
}

.mat-snack-bar-container {
    &.error-snack-bar {
        color: rgb(255 255 255);
        background: var(--theme-client-red);
        text-align: center;
    }

    &.success-snack-bar {
        color: rgb(255 255 255);
        background: var(--theme-client-green);
        text-align: center;

        &.open-cart {
            margin-bottom: 120px;
        }
    }
}

.floating-banner {
    .mat-dialog-container {
        background: transparent;
        box-shadow: none;
    }
}

.dialog-not-spacing {
    .mat-dialog-container {
        padding: 0;
        margin: 0;
    }

    .mat-dialog-content {
        padding: 0;
        margin: 0;
    }

    &.with-radius {
        .mat-dialog-container {
            border-radius: 1rem;
        }
    }
}

.selection-button {
    height: 32px !important;
    min-width: 132px;
    display: flex !important;
    align-items: center;
    font-size: 0.8rem;
}

.mat-tab-label-container {
    z-index: 0 !important;
}

.gallery-tabs {
    .mat-tab-body.mat-tab-body-active {
        z-index: unset !important;
    }
}

@keyframes loading {
    100% {
        transform: translateX(100%);
    }
}

.mat-tab-nav-bar, .mat-tab-header {
    border-bottom: none;
}

.mat-tab-labels {
    display: flex;
    justify-content: center;

    &[aria-selected="true"] {
        background-color: red;
    }
}

.mat-tab-labels > div {
    color: #484848;
    opacity: 1;

    &[aria-selected="true"] {
        border: 2px solid var(--theme-client-color);
        color: var(--theme-client-color);
    }
}

.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
    border-color: var(--theme-client-color) !important;
}
.mat-radio-button.mat-accent .mat-radio-inner-circle {
    background-color: var(--theme-client-color) !important;
}


.mat-tab-group.mat-primary .mat-ink-bar, .mat-tab-nav-bar.mat-primary .mat-ink-bar {
    background-color: transparent !important;
}

.dialog-finish {
    .mat-progress-spinner circle, .mat-spinner circle {
        stroke: var(--theme-client-green) !important;
    }
}

.mat-dialog-content {
    &::-webkit-scrollbar {
        width: 4px;
    }
    &::-webkit-scrollbar-thumb {
        background: var(--theme-client-color);
    }
    &::-webkit-scrollbar-track {
        background-color: #f0f0f0;
    }
    &::selection {
        background: var(--theme-client-color);
        color: #fff;
    }
}

#chatbotmobile {
    &.hidden {
        display: none;
    }
}

/* Google traslate */
    /*#google_translate_element,*/
.goog-te-banner-frame,
.goog-te-balloon-frame,
#goog-gt-tt,
.goog-te-balloon-frame,
.goog-tooltip,
.goog-tooltip:hover {
    display: none !important;
}

.goog-te-gadget .goog-te-combo {
    margin: 0 !important;
}

.mat-toolbar-multiple-rows {
    min-height: auto !important;
}

.hide-mat-step-header {
    .mat-step-header {
        display: none;
    }
}
